class ReadView {
  #allArticles = document.querySelectorAll(".article");
  #allCategories = document.querySelectorAll(".category--item");

  _selectCategory(category) {
    this.#allArticles.forEach((artic) => {
      const chosenId = artic.dataset.article;

      if (chosenId === category || category === "all-categories")
        artic.classList.remove("hidden");
      if (chosenId !== category && category !== "all-categories")
        artic.classList.add("hidden");
    });
  }

  listenCategory() {
    this.#allCategories.forEach((category) => {
      category.addEventListener("click", (e) => {
        this._selectCategory(e.target.id);
      });
    });
  }

  _handleIntersection(entries) {
    if (!entries[0].isIntersecting) return;

    const id = entries[0].target.dataset.article;

    document.querySelectorAll(".category--item").forEach((cat) => {
      cat.classList.remove("highlight");
    });

    document.querySelector(`#${id}`).classList.add("highlight");
  }

  observeTopScroll() {
    const observer = new IntersectionObserver(this._handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 1,
    });

    this.#allArticles.forEach((article) => {
      observer.observe(article);
    });
  }
}

export default new ReadView();
